import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Stack,
  TextField,
  Tab,
  Tabs,
  Chip,
} from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { endOfDay, startOfDay, sub } from "date-fns";
import { round, mean } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { useModal } from "components/hooks";
import MeasureList from "components/MeasureList";
import MeasureLineChart from "components/Measures/MeasureLineChart";
import MeasureSearchForm from "components/Measures/MeasureSearchForm";
import { MeasureToolbarButton } from "components/Measures/MeasureToolbar";
import StelModal from "components/StelModal";
import { get } from "utils/io";

const tabs = [
  { index: 0, label: "Chart" },
  { index: 1, label: "Raw Data" },
];

function Hub({ hubId }) {
  // const [measures, setMeasures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({
    takenAfterDate: sub(new Date(), { months: 1 }),
    takenBeforeDate: endOfDay(new Date()),
    deviceMac: undefined,
    status: undefined,
  });
  const [pagination, setPagination] = useState({ pageSize: 20, page: 0 });
  const [totalCount, setTotalCount] = useState(0);
  const [selectedMeasures, setSelectedMeasures] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [autoRefreshMeasure, setAutoRefreshMeasure] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const {
    isModal: isModalOpen,
    openModal: handleOpenModal,
    closeModal: handleCloseModal,
  } = useModal();

  const handleTabChange = (_, newValue) => setTabIndex(newValue);

  useEffect(() => {
    const fetchMeasures = async () => {
      setLoading(true);
      try {
        const {
          data: { measures: measureData },
        } = await get("/measures", {
          esn: hubId,
          taken_after: searchParams.takenAfterDate,
          taken_before: searchParams.takenBeforeDate,
          device_mac: searchParams.macAddress,
          status: searchParams.status,
        });
        const bpMeasures = measureData.filter(
          ({ type }) => type === "bloodpressure"
        );
        setSearchResults(bpMeasures);
        setTotalCount(bpMeasures.length);
      } catch (err) {
        enqueueSnackbar("Error fetching measures", { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    fetchMeasures();
  }, [hubId, searchParams]);

  const handleSearch = (params) => {
    setSearchParams(params);
  };

  const chartData = searchResults
    // .filter((measure) => measure.type === "bloodpressure")
    .map((measure) => ({ ...measure.value, timestamp: measure.timestamp }))
    .reverse();

  const getTicks = (startDate, endDate) => {
    const ticks = [];
    let currDate = startDate;
    while (currDate < endDate) {
      ticks.push(currDate);
      const tempDate = new Date(currDate);
      currDate = startOfDay(tempDate.setDate(tempDate.getDate() + 1)).valueOf();
    }
    return ticks;
  };

  const onSelectionChanged = (selectionModel) => {
    setSelectedMeasures(selectionModel);
  };

  const onChangePage = (page) => {
    setPagination((prev) => ({ ...prev, page }));
  };

  const onChangeRowPerPage = (pageSize) => {
    setPagination((prev) => ({ ...prev, pageSize }));
  };

  const refreshMeasureList = () => setPagination({ ...pagination });

  const formatMeasureValue = (type, value) =>
    type === "bloodpressure"
      ? `${round(value.systolic)}/${round(value.diastolic)}, ${round(
          value.bpm
        )} bpm`
      : JSON.stringify(value);

  const formatMeasuresToCopy = () =>
    searchResults
      .map(
        (measure) =>
          `${new Date(
            measure.timestamp
          ).toLocaleString()}, ${formatMeasureValue(
            measure.type,
            measure.value
          )}`
      )
      .join("\n");

  const computeAverageLastThreeMeasures = (measures) => {
    if (measures.length === 0) {
      return {
        bp: "No data",
        heartRate: "No data",
      };
    }

    const avgSys = round(
      mean(measures.map((measure) => measure.value.systolic))
    );
    const avgDia = round(
      mean(measures.map((measure) => measure.value.diastolic))
    );
    const avgHeartRate = round(
      mean(measures.map((measure) => measure.value.bpm))
    );

    return {
      bp: `${avgSys}/${avgDia} mmHg`,
      heartRate: `${avgHeartRate} bpm`,
    };
  };

  return (
    <Stack spacing={3}>
      <Typography fontWeight="bold" fontSize="20px">
        Hub {hubId}
      </Typography>
      <MeasureSearchForm
        onSubmit={handleSearch}
        searchCriterias={{
          hubId: false,
          macAddress: true,
          status: true,
        }}
      />

      <Stack
        direction="row"
        spacing={5}
        alignItems="center"
        sx={{
          p: 1,
          border: `1px solid ${grey[400]}`,
          borderRadius: 1,
          width: "fit-content",
        }}
      >
        <Typography variant="p2" sx={{ fontWeight: "bold" }}>
          Average of Last 3 Measurements
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Blood Pressure</Typography>
          <Chip
            label={
              computeAverageLastThreeMeasures(searchResults.slice(0, 3)).bp
            }
            color="primary"
          />
          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Heart Rate</Typography>
            <Chip
              label={
                computeAverageLastThreeMeasures(searchResults.slice(0, 3))
                  .heartRate
              }
              color="primary"
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack sx={{ border: `1px solid ${grey[400]}`, borderRadius: 1 }}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          {tabs.map((tab) => (
            <Tab key={tab.index} label={tab.label} />
          ))}
        </Tabs>

        <TabPanel tabIndex={tabIndex} index={0}>
          {(() => {
            if (loading) {
              return (
                <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                  <CircularProgress />
                </Box>
              );
            }

            if (chartData.length === 0) {
              return (
                <Typography sx={{ color: grey[500] }}>
                  No Measurements Found
                </Typography>
              );
            }

            return (
              <MeasureLineChart
                data={chartData}
                isShowExportCSVButton={false}
                graphs={[
                  {
                    label: "Blood Pressure",
                    value: [
                      {
                        key: "systolic",
                        color: "#8884d8",
                        bounds: [{ color: red[500], value: 180 }, 135],
                      },
                      {
                        key: "diastolic",
                        color: "#82ca9d",
                        bounds: [85],
                      },
                    ],
                  },
                  {
                    label: "Heart Rate",
                    value: [
                      { key: "bpm", color: "#3182BD", bounds: [60, 100] },
                    ],
                  },
                ]}
                lineChartProps={{
                  margin: { left: -20 },
                }}
                labelProps={{ sx: { mt: "10px" } }}
                XAxisProps={{
                  ticks: getTicks(
                    new Date(chartData[0].timestamp).valueOf(),
                    endOfDay(
                      new Date(chartData[chartData.length - 1].timestamp)
                    ).valueOf()
                  ),
                  padding: { left: 50, right: 30 },
                }}
              />
            );
          })()}
        </TabPanel>

        <TabPanel tabIndex={tabIndex} index={1}>
          <MeasureList
            rows={searchResults}
            loading={loading}
            onSelectionChanged={onSelectionChanged}
            pageSize={pagination.pageSize}
            onPageSizeChange={onChangeRowPerPage}
            rowsPerPageOptions={[20, 25, 50, 100]}
            rowCount={totalCount}
            onPageChange={onChangePage}
            refreshMeasureList={refreshMeasureList}
            selectedMeasures={selectedMeasures}
            searchResults={searchResults}
            setAutoRefreshMeasure={setAutoRefreshMeasure}
            autoRefreshMeasure={autoRefreshMeasure}
            hideColumns={["type", "delivery_status", "transmitting_hub_id"]}
            columnOrder={[
              "timestamp",
              "value",
              "hub_id",
              "device_make",
              "device_model",
              "device_mac",
            ]}
            columnProps={{
              value: {
                valueGetter: (params) =>
                  formatMeasureValue(params.row.type, params.row.value),
              },
            }}
            showDeliveries={false}
            toolbarProps={{
              customComponents: [
                {
                  name: "copyButton",
                  value: (
                    <>
                      <MeasureToolbarButton
                        text="Copy"
                        onClick={handleOpenModal}
                      />
                      <CopyModal
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        text={formatMeasuresToCopy()}
                      />
                    </>
                  ),
                },
              ],
            }}
          />
        </TabPanel>
      </Stack>
    </Stack>
  );
}

Hub.propTypes = {
  hubId: PropTypes.string.isRequired,
};

function CopyModal(props) {
  const { text, open, onClose } = props;
  const textInputRef = useRef(null);

  const selectTextInput = () => {
    const input = textInputRef.current;
    input.focus();
    input.select();
  };

  return (
    <StelModal
      open={open}
      onClose={onClose}
      title="Copy to Clipboard"
      width={500}
    >
      <TextField
        inputRef={textInputRef}
        value={text}
        fullWidth
        multiline
        maxRows={10}
      />
      <Stack direction="row" justifyContent="flex-end" sx={{ mt: "10px" }}>
        <Button onClick={selectTextInput} variant="contained">
          Select All
        </Button>
      </Stack>
    </StelModal>
  );
}

CopyModal.propTypes = {
  text: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, tabIndex, index, ...other } = props;

  return (
    <Box hidden={tabIndex !== index} id={`tab-panel-${index}`} {...other}>
      {tabIndex === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default Hub;
