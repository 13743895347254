import { Card, CardActionArea, Unstable_Grid2 as Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { get } from "../../utils/io";
import MainContentContainer from "../Layouts/MainContentContainer";

export default function ProgramsList() {
  const history = useHistory();
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    get("/programs").then((res) => {
      setPrograms(res.data);
    });
  }, []);

  return (
    <MainContentContainer title="Programs">
      <Grid container spacing={2} gap={2}>
        {programs.map((program) => (
          <Card key={program.id}>
            <CardActionArea
              onClick={() => history.push(`/programs/${program.id}`)}
              sx={{ p: 5, backgroundColor: grey }}
            >
              {program.name}
            </CardActionArea>
          </Card>
        ))}
      </Grid>
    </MainContentContainer>
  );
}
