import { Check, Clear } from "@mui/icons-material";
import { Box, Divider, Skeleton, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { mean, round, chunk } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { usePagination } from "components/hooks";
import StelDataGrid from "components/StelDataGrid";
import VirtualHubIndicator from "components/VirtualHubIndicator";
import { get } from "utils/io";

export default function HubList({ programId }) {
  const theme = useTheme();
  const history = useHistory();

  const {
    pagination,
    handlePageChange,
    handleRowPerPageChange,
    rowCount,
    setRowCount,
  } = usePagination();

  const [hubs, setHubs] = useState([]);
  const [bpLoadingStates, setBpLoadingStates] = useState({});
  const [bpData, setBpData] = useState({});
  const [lastMeasurementTimes, setLastMeasurementTimes] = useState({});

  const dataGridColumns = [
    {
      field: "id",
      flex: 0.5,
      headerName: "Hub ID",
      valueGetter: (params) => params.value.substring(0, 10),
      renderCell: (params) => (
        <>
          {params.value}
          {params.row.isVirtual && (
            <Box sx={{ ml: "10px" }}>
              <VirtualHubIndicator />
            </Box>
          )}
        </>
      ),
    },
    {
      field: "connected",
      align: "center",
      flex: 1,
      headerAlign: "center",
      headerName: "Connected",
      renderCell: (params) => {
        if (params.value) {
          return <Check sx={{ color: theme.palette.success.dark }} />;
        }
        return <Clear color="error" />;
      },
      width: 150,
    },
    {
      field: "averageBP",
      flex: 1,
      headerName: "Average BP",
      renderCell: (params) => {
        if (
          bpLoadingStates[params.row.hubId] === undefined ||
          bpLoadingStates[params.row.hubId]
        ) {
          return <Skeleton variant="text" width={100} animation="wave" />;
        }
        if (!params.value) return "N/A";

        const [bp, hr] = params.value.split(" | ");
        return (
          <Stack
            direction="row"
            spacing={1}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ bgcolor: "black" }}
              />
            }
            alignItems="center"
            sx={{ minWidth: 165 }}
          >
            <Box sx={{ width: 95 }}>{bp}</Box>
            <Box sx={{ width: 65 }}>{hr}</Box>
          </Stack>
        );
      },
      valueGetter: (params) => bpData[params.row.hubId],
      width: 180,
    },
    {
      field: "lastMeasurement",
      flex: 1,
      headerName: "Last Measurement",
      renderCell: (params) => {
        if (
          bpLoadingStates[params.row.hubId] === undefined ||
          bpLoadingStates[params.row.hubId]
        ) {
          return <Skeleton variant="text" width={150} animation="wave" />;
        }
        return params.value || "N/A";
      },
      valueGetter: (params) => lastMeasurementTimes[params.row.hubId],
      width: 200,
    },
  ];

  useEffect(() => {
    (async () => {
      const {
        data: { hubs, total_count: totalCount },
      } = await get(
        `/programs/${programId}/hubs?limit=${pagination.pageSize}&offset=${
          pagination.page * pagination.pageSize
        }`
      );
      setHubs(
        hubs.map(
          ({
            connected,
            hub_group: hubGroup,
            version_status: versionStatus,
            is_virtual: isVirtual,
            hub_id: hubId,
          }) => ({
            id: hubId,
            hubId,
            connected,
            hubGroup,
            versionStatus,
            isVirtual,
            averageBP: null, // This will be populated by future API calls
          })
        )
      );
      setRowCount(totalCount);
    })();
  }, [programId, setRowCount, pagination]);

  useEffect(() => {
    const fetchBPData = async (hub) => {
      try {
        setBpLoadingStates((prev) => ({ ...prev, [hub.hubId]: true }));

        const {
          data: { measures },
        } = await get("/measures", {
          esn: hub.hubId,
          limit: 5,
        });

        const bpMeasurements = measures
          .filter((m) => m.type === "bloodpressure")
          .slice(0, 3);

        if (bpMeasurements.length === 0) {
          return;
        }

        const lastTimestamp = new Date(
          bpMeasurements[0].timestamp
        ).toLocaleString();
        setLastMeasurementTimes((prev) => ({
          ...prev,
          [hub.hubId]: lastTimestamp,
        }));

        const avgSystolic = round(
          mean(bpMeasurements.map((m) => m.value.systolic))
        );
        const avgDiastolic = round(
          mean(bpMeasurements.map((m) => m.value.diastolic))
        );
        const avgHeartRate = round(
          mean(bpMeasurements.map((m) => m.value.bpm))
        );

        setBpData((prev) => ({
          ...prev,
          [hub.hubId]: `${avgSystolic}/${avgDiastolic} mmHg | ${avgHeartRate} bpm`,
        }));
      } catch (err) {
        enqueueSnackbar("Error fetching BP measurements", { variant: "error" });
      } finally {
        setBpLoadingStates((prev) => ({ ...prev, [hub.hubId]: false }));
      }
    };

    const chunks = chunk(hubs, 10);
    chunks.reduce(
      (promise, batch) =>
        promise.then(() => Promise.all(batch.map(fetchBPData))),
      Promise.resolve()
    );
  }, [hubs]);

  return (
    <StelDataGrid
      columns={dataGridColumns}
      rows={hubs}
      loading={false}
      onRowClick={({ row }) =>
        history.push(`/programs/${programId}?hub=${row.hubId}`)
      }
      pageSize={pagination.pageSize}
      onPageSizeChange={handleRowPerPageChange}
      rowsPerPageOptions={[20, 25, 50, 100]}
      rowCount={rowCount}
      onPageChange={handlePageChange}
      paginationMode="server"
    />
  );
}

HubList.propTypes = {
  programId: PropTypes.string.isRequired,
};
