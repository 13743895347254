import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { timeTicks } from "d3";
import { add, format, sub } from "date-fns";
import groupBy from "lodash/groupBy";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import MeasureLineChart from "components/Measures/MeasureLineChart";
import { get } from "utils/io";

const typeToGraphsMap = {
  bloodpressure: {
    getTextRepresentation: (value) =>
      `${value.systolic}/${value.diastolic}, ${value.bpm} bpm`,
    graphs: [
      {
        label: "Blood Pressure",
        value: [
          {
            key: "systolic",
            color: "#8884d8",
            bounds: [120],
          },
          { key: "diastolic", color: "#82ca9d", bounds: [80] },
          { key: "bpm", color: "#3182BD" },
        ],
      },
      // {
      //   label: "Heart Rate",
      //   value: [{ key: "bpm", color: "#3182BD", bounds: [60, 100] }],
      // },
    ],
  },
  weight: {
    getTextRepresentation: (value) => `${value.weight_lbs} lbs`,
    graphs: [
      {
        label: "Weight (lbs)",
        value: [{ key: "weight_lbs", color: "#3182BD", bounds: [70, 400] }],
      },
    ],
  },
  temperature: {
    getTextRepresentation: (value) => `${value.celsius} C`,
    graphs: [
      {
        label: "Temperature (C)",
        value: [{ key: "celsius", color: "#3182BD", bounds: [33, 40] }],
      },
    ],
  },
  glucose: {
    getTextRepresentation: (value) => `${value.mg_dl} mg/dL`,
    graphs: [
      {
        label: "Glucose",
        value: [{ key: "mg_dl", color: "#3182BD", bounds: [70, 240] }],
      },
    ],
  },
  "isf-glucose": {
    getTextRepresentation: (value) => `${value.mg_dl} mg/dL`,
    graphs: [
      {
        label: "Glucose (Interstital Fluid)",
        value: [{ key: "mg_dl", color: "#3182BD", bounds: [70, 240] }],
      },
    ],
  },
  pulseox: {
    getTextRepresentation: (value) => `${value.spo2}%, ${value.bpm} bpm`,
    graphs: [
      {
        label: "Oxygen Saturation",
        value: [
          { key: "spo2", color: "#3182BD", bounds: [95], yAxis: [75, 100] },
        ],
      },
      {
        label: "Heart Rate",
        value: [{ key: "bpm", color: "#3182BD", bounds: [60, 100] }],
      },
    ],
  },
  // spirometer: {
  //   graphs: [
  //     {
  //       label: "Spirometry 1",
  //       value: [
  //         { key: "fev1", color: "#8884d8" },
  //         { key: "fev6", color: "#82ca9d" },
  //         "fev1fev6",
  //       ],
  //     },
  //     {
  //       label: "Spirometry 2",
  //       value: [{ key: "fvc", color: "#82ca9d" }, "fef2575"],
  //     },
  //     { label: "Spirometry 3", value: ["pef"] },
  //   ],
  // },
  wearable: {
    getTextRepresentation: (value) => `${value.steps} steps, ${value.bpm} bpm`,
    graphs: [
      { label: "Steps", value: ["steps"] },
      {
        label: "Heart Rate",
        value: [{ key: "bpm", color: "#3182BD", bounds: [60, 100] }],
      },
    ],
  },
  // inr: {
  //   graphs: [
  //     { label: "INR", value: ["inr"] },
  //     { label: "PT", value: ["pt"] },
  //   ],
  // },
};

const getMeasures = async (hubId, takenAfter = undefined) => {
  try {
    const measureRes = await get("/measures", {
      esn: hubId,
      taken_after:
        takenAfter?.toISOString() ||
        sub(new Date(), { weeks: 2 }).toISOString(),
      limit: 5000,
    });
    const newMeasures = measureRes.data.measures;
    return newMeasures;
  } catch (err) {
    enqueueSnackbar("Failed to get recent measurements", {
      variant: "error",
    });
    return [];
  }
};

function processMeasures(measures) {
  const chartData = measures
    .filter((measure) => measure.type !== "pillcap")
    .map((measure) => ({
      type: measure.type,
      timestamp: measure.timestamp,
      ...measure.value,
    }));
  const groupedMeasures = groupBy(chartData, "type");
  return groupedMeasures;
}

export default function Hub({ hubId }) {
  const [measures, setMeasures] = useState([]);
  const [lastMeasureTime, setLastMeasureTime] = useState();
  const [initialLoading, setInitialLoading] = useState(true);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);

  const getLatestMeasures = useCallback(async () => {
    setRefreshLoading(true);
    const additionalMeaures = await getMeasures(
      hubId,
      lastMeasureTime
        ? add(new Date(lastMeasureTime), { seconds: 1 })
        : undefined
    );
    setMeasures([...additionalMeaures, ...measures]);
    if (additionalMeaures.length > 0) {
      setLastMeasureTime(additionalMeaures[0].timestamp);
    }
    setRefreshLoading(false);
  }, [lastMeasureTime, measures, hubId]);

  useEffect(() => {
    const getMeasuresWithLoading = async () => {
      setInitialLoading(true);
      const newMeasures = await getMeasures(hubId);
      setMeasures(newMeasures);
      setLastMeasureTime(newMeasures[0]?.timestamp);
      setAutoRefresh(true);
      setInitialLoading(false);
    };

    getMeasuresWithLoading();
  }, [hubId]);

  useEffect(() => {
    if (autoRefresh) {
      const interval = setInterval(getLatestMeasures, 10000);
      return () => clearInterval(interval);
    }
    return null;
  }, [autoRefresh, getLatestMeasures]);

  const groupedChartData = processMeasures(measures);

  return initialLoading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Stack
        sx={{ my: 2, alignItems: "center", justifyContent: "space-between" }}
        direction="row"
      >
        <Typography fontWeight="bold" fontSize="20px">
          Hub {hubId}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Tooltip
            title={
              <Typography variant="body2">
                Refresh measurements every 10 seconds
              </Typography>
            }
            arrow
          >
            <FormControlLabel
              control={<Switch checked={autoRefresh} />}
              onChange={() => setAutoRefresh(!autoRefresh)}
              label="Auto Refresh"
              labelPlacement="start"
            />
          </Tooltip>
          <LoadingButton
            loading={refreshLoading}
            onClick={getLatestMeasures}
            variant="contained"
          >
            Refresh
          </LoadingButton>
        </Stack>
      </Stack>
      <Stack spacing={2}>
        {Object.keys(groupedChartData).length === 0 && "No data"}
        {Object.keys(groupedChartData).map((type) => {
          const chartData = groupedChartData[type];
          return (
            <Card key={type}>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  py: 2,
                  backgroundColor: "#ececf2",
                }}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Stack sx={{ flex: 2 }}>
                  <MeasureLineChart
                    sx={{ py: "20px" }}
                    key={type}
                    data={chartData}
                    isShowExportCSVButton={false}
                    graphs={typeToGraphsMap[type].graphs}
                    lineChartProps={{
                      margin: { top: 5, right: 0, left: 0, bottom: 40 },
                    }}
                    labelProps={{
                      sx: { fontSize: "18px", ml: "25px", mb: "5px" },
                    }}
                    XAxisProps={{
                      scale: "time",
                      angle: -30,
                      dx: -40,
                      dy: 25,
                      ticks: timeTicks(
                        new Date(chartData[0].timestamp).valueOf(),
                        new Date(
                          chartData[chartData.length - 1].timestamp
                        ).valueOf(),
                        10
                      ),
                      interval: 0,
                      tickCount: 10,
                      padding: { left: 50, right: 30 },
                    }}
                    tickTimeFormat="M/d p"
                    toolTipLabelTimeFormat="M/d p"
                  />
                </Stack>
                <Stack
                  spacing={1}
                  sx={{
                    flex: 1,
                    maxHeight: "250px",
                    overflowY: "auto",
                    pr: 2,
                  }}
                >
                  {groupedChartData[type].map((point) => (
                    <Card
                      key={crypto.randomUUID()}
                      sx={{ p: 1, minHeight: "24px" }}
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        divider={
                          <Divider
                            sx={{ background: "grey" }}
                            orientation="vertical"
                            flexItem
                          />
                        }
                      >
                        <Typography>{`${format(
                          new Date(point.timestamp),
                          "M/dd/yy p"
                        )}`}</Typography>
                        <Typography>{`${typeToGraphsMap[
                          type
                        ].getTextRepresentation(point)}`}</Typography>
                      </Stack>
                    </Card>
                  ))}
                </Stack>
              </Stack>
            </Card>
          );
        })}
      </Stack>
    </>
  );
}

Hub.propTypes = {
  hubId: PropTypes.string.isRequired,
};
