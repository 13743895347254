import React, { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get, getOrgAlias } from "../../utils/io";
import MainContentContainer from "../Layouts/MainContentContainer";

export default function DynamicProgramDashboard() {
  const { programId } = useParams();
  const orgAlias = getOrgAlias();

  const [program, setProgram] = useState({});
  const [ProgramComponent, setProgramComponent] = useState();

  // Required to ensure webpack packages the necessary files
  const customProgramsModuleContext = require.context(
    "./customPrograms",
    true,
    /\.(js|jsx)$/
  );

  useEffect(() => {
    (async () => {
      const { data } = await get(`/programs/${programId}`);
      setProgram(data);
      const dynamicDashboardComponent = data.ui_component;
      /*
      Program components are always in the `customProgams` dir and assumed
      to be contained in a subdireectory named with the org's alias. However,
      if necessary a component can be shared across orgs and the path to the
      component can be prefixed with '@' to signify the path is relative to the
      customPrograms root.

      Since a require.context is used, paths must include exact file names,
      included extensions (.js) and if using an index file, /index.js
      */
      const relativeComponentPath = `${
        dynamicDashboardComponent.startsWith("@")
          ? dynamicDashboardComponent.substring(1)
          : `${orgAlias}/${dynamicDashboardComponent}`
      }`;
      const componentModule = React.lazy(() =>
        Promise.resolve(
          customProgramsModuleContext(`./${relativeComponentPath}`)
        )
      );

      setProgramComponent(() => componentModule);
    })();
  }, [programId, orgAlias, customProgramsModuleContext]);

  return (
    <MainContentContainer title={program.name || "Loading..."}>
      <Suspense fallback="Loading...">
        {!ProgramComponent ? (
          "Loading..."
        ) : (
          <ProgramComponent programId={program.id} />
        )}
      </Suspense>
    </MainContentContainer>
  );
}
