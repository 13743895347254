import { Check, Clear } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { usePagination } from "components/hooks";
import StelDataGrid from "components/StelDataGrid";
import VirtualHubIndicator from "components/VirtualHubIndicator";
import { get } from "utils/io";

export default function HubList({ programId }) {
  const theme = useTheme();
  const history = useHistory();

  const { pagination, setPagination } = usePagination();

  const [hubs, setHubs] = useState([]);

  const dataGridColumns = [
    {
      field: "id",
      flex: 0.5,
      headerName: "Hub ID",
      valueGetter: (params) => params.value.substring(0, 10),
      renderCell: (params) => (
        <>
          {params.value}
          {params.row.isVirtual && (
            <Box sx={{ ml: "10px" }}>
              <VirtualHubIndicator />
            </Box>
          )}
        </>
      ),
    },
    {
      field: "connected",
      align: "center",
      flex: 1,
      headerAlign: "center",
      headerName: "Connected",
      renderCell: (params) => {
        if (params.value) {
          return <Check sx={{ color: theme.palette.success.dark }} />;
        }

        return <Clear color="error" />;
      },
      width: 150,
    },
  ];

  useEffect(() => {
    (async () => {
      const {
        data: { hubs },
      } = await get(`/programs/${programId}/hubs`);
      setHubs(
        hubs.map(
          ({
            connected,
            hub_group: hubGroup,
            version_status: versionStatus,
            is_virtual: isVirtual,
            hub_id: hubId,
          }) => ({
            id: hubId,
            hubId,
            connected,
            hubGroup,
            versionStatus,
            isVirtual,
          })
        )
      );
    })();
  }, [programId]);

  return (
    <StelDataGrid
      columns={dataGridColumns}
      rows={hubs}
      loading={false}
      onRowClick={({ row }) =>
        history.push(`/programs/${programId}?hub=${row.hubId}`)
      }
      pageSize={pagination.pageSize}
      onPageSizeChange={(pageSize) =>
        setPagination((pre) => ({ ...pre, pageSize }))
      }
      rowsPerPageOptions={[20, 25, 50, 100]}
      rowCount={pagination.totalCount}
      onPageChange={(page) => setPagination((pre) => ({ ...pre, page }))}
      paginationMode="server"
    />
  );
}
HubList.propTypes = {
  programId: PropTypes.string.isRequired,
};
