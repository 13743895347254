import {
  AddCardOutlined as AddCardOutlinedIcon,
  AssignmentReturn as AssignmentReturnIcon,
  Receipt as ReceiptIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  MonitorWeight,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import React from "react";
import { isAdmin, isSandbox } from "../../../utils/io";
import NavLink, { ProtectedNavLink } from "./NavLink";

export default function NavSectionTwo() {
  return (
    <Stack>
      {[
        {
          icon: AddCardOutlinedIcon,
          title: "Order Hubs",
          path: "/hub-orders/request",
          permission: "CreateHubOrders",
          isShown: () => !isAdmin(),
        },
        {
          icon: ReceiptIcon,
          title: isAdmin() ? "Hub Orders" : "Order History",
          path: "/hub-orders",
          permission: "ViewHubOrders",
        },
        {
          icon: AssignmentReturnIcon,
          title: "Hub Returns",
          path: "/hub-returns",
          permission: "ViewHubReturns",
          isShown: () => !isSandbox(),
        },
        {
          icon: ConfirmationNumberIcon,
          title: "Support Tickets",
          path: "/troubleshoot-tickets",
          permission: "ViewTroubleshootTickets",
          isShown: () => !isSandbox() && isAdmin(),
        },
        {
          icon: MonitorWeight,
          title: "Device Ecosystem",
          path: "/device-ecosystem",
        },
      ].map((page) => {
        const { permission, ...rest } = page;
        if (permission) {
          return (
            <ProtectedNavLink
              permission={permission}
              key={page.path}
              {...rest}
            />
          );
        }
        return <NavLink key={page.path} {...rest} />;
      })}
    </Stack>
  );
}
