import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useLocation, useParams, useHistory } from "react-router";
import { post } from "../../../utils/io";
import { TROUBLESHOOT_SUBMIT_BUTTON, TROUBLESHOOT_TYPE } from "../constants";

function useSubmitTroubleshootForm(props) {
  const { formMethod, ticketReturn, setTicketReturn, setTickets } = props;
  const location = useLocation();
  const history = useHistory();
  const [completeLoading, setCompleteLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [requiredReplacementHub, setRequiredReplacementHub] = useState();
  const currentTab = location.hash.substring(1);
  const { hubId } = useParams();

  const formatActiveReturnPayload = () => {
    const { getValues } = formMethod;

    const formValues = getValues();

    const {
      resolution,
      replacement_hub_id: replacementHubId,
      ...resFormValues
    } = formValues;

    const payload = {
      ...(resolution && { resolution }),
      ...(replacementHubId && {
        replacement_hub_id: replacementHubId,
      }),
      form: resFormValues,
    };

    return payload;
  };

  const formatDeviceIssuePayload = (
    formValuesOriginal = {},
    submitKey = ""
  ) => {
    const payload = {
      hub_id: hubId,
      triage: {
        type: currentTab.replaceAll("-", "_"),
        status: submitKey,
        form: {
          notes: formValuesOriginal.notes,
        },
        context: {
          device_model_id: formValuesOriginal.device_model_id,
          issue_type: formValuesOriginal.issue_type,
        },
      },
    };
    return payload;
  };

  const getPayload = (formValuesOriginal = {}, submitKey = "") => {
    const payload = {
      hub_id: hubId,
      triage: {
        type: currentTab.replaceAll("-", "_"),
        status: submitKey,
        form: formValuesOriginal,
        ...(formValuesOriginal.connectivity_issues === "1_device" && {
          context: { device_model_id: formValuesOriginal.device_model_id },
        }),
      },
    };

    switch (currentTab) {
      case TROUBLESHOOT_TYPE.HUB_DEVICE_CONNECTIVITY:
        return payload;
      case TROUBLESHOOT_TYPE.ACTIVE_RETURN:
        return formatActiveReturnPayload();
      case TROUBLESHOOT_TYPE.HEALTH_DEVICE_ISSUE:
        return formatDeviceIssuePayload(formValuesOriginal, submitKey);
      default:
        return payload;
    }
  };

  const processSaveTicket = async (payload) => {
    setSaveLoading(true);
    const res = await post(`/troubleshoot/tickets/${ticketReturn.id}`, payload);
    if (res.status === 200) {
      setTicketReturn(res.data);
      enqueueSnackbar("Changes saved", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(
        res.data.detail ||
          "Failed to save ticket, please contact support@stel.life",
        {
          variant: "error",
        }
      );
    }
    setSaveLoading(false);
  };

  const processCloseTicket = async () => {
    setCompleteLoading(true);
    const res = await post(`/troubleshoot/tickets/${ticketReturn.id}/cancel`);
    if (res.status === 200) {
      history.replace(`/hubs/${hubId}/troubleshoot`);
      setTicketReturn({});
      enqueueSnackbar("Ticket cancelled", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(res.data.detail, {
        variant: "error",
      });
    }
    return setCompleteLoading(false);
  };

  const handleSaveTicket = async () => {
    const payload = getPayload();
    await processSaveTicket(payload);
  };

  const processActiveReturnCompleted = async () => {
    const { trigger } = formMethod;
    const returnType = ticketReturn?.details?.triage?.return_type || "";
    const replaceHubId = ticketReturn.details?.intake?.replacement_hub_id || "";

    if (
      [
        TROUBLESHOOT_SUBMIT_BUTTON.REPLACE_HUB.value,
        TROUBLESHOOT_SUBMIT_BUTTON.REPLACE_KIT.value,
      ].includes(returnType) &&
      !replaceHubId
    ) {
      setRequiredReplacementHub("A replacement hub is required");
      return;
    }
    const isFormValid = await trigger();
    if (!isFormValid) return;
    await handleSaveTicket();
    setCompleteLoading(true);
    const res = await post(`/troubleshoot/tickets/${ticketReturn.id}/complete`);
    if (res.status === 200) {
      history.replace(`/hubs/${hubId}`);
      setTicketReturn({});
      enqueueSnackbar(`Completed return for hub ${hubId}`, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(res.data.detail, {
        variant: "error",
      });
    }
    setCompleteLoading(false);
  };

  const processRequest = async (payload) => {
    const { trigger } = formMethod;

    const isFormValid = await trigger();

    if (!isFormValid) return;
    const res = await post("/troubleshoot/tickets", payload);
    if (res.status === 200) {
      setTickets([res.data, ticketReturn]);
      window.location.search =
        res.data.details.status === "closed" ? "history" : "active-return";
      enqueueSnackbar("Submitted troubleshoot ticket", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(res.data.detail, {
        variant: "error",
      });
    }
  };

  const handleResolvedTicketSubmit = (submitAction) => {
    const { getValues } = formMethod;

    const formValues = getValues();

    const payload = getPayload(formValues, submitAction);
    processRequest(payload);
  };

  const handleInitiateReturnSubmit = (secondSubmitKey) => {
    const { getValues } = formMethod;

    const formValues = getValues();

    const payload = getPayload(
      formValues,
      TROUBLESHOOT_SUBMIT_BUTTON.RETURN.value
    );
    payload.triage.return_type = secondSubmitKey;

    processRequest(payload);
  };

  const handleActiveReturnCompleted = () => {
    processActiveReturnCompleted();
  };
  const handleCloseTicket = () => {
    processCloseTicket();
  };

  const handleFormSubmit = (submitAction) => {
    switch (submitAction) {
      case TROUBLESHOOT_SUBMIT_BUTTON.RESOLVED.value:
        return handleResolvedTicketSubmit(submitAction);
      case TROUBLESHOOT_SUBMIT_BUTTON.ALERT_STEL.value:
        return handleResolvedTicketSubmit(submitAction);
      case TROUBLESHOOT_SUBMIT_BUTTON.REPLACE_HUB.value:
        return handleInitiateReturnSubmit(submitAction);
      case TROUBLESHOOT_SUBMIT_BUTTON.RETURN_ONLY.value:
        return handleInitiateReturnSubmit(submitAction);
      case TROUBLESHOOT_SUBMIT_BUTTON.REPLACE_KIT.value:
        return handleInitiateReturnSubmit(submitAction);
      case TROUBLESHOOT_SUBMIT_BUTTON.SAVE_TICKET.value:
        return handleSaveTicket();
      case TROUBLESHOOT_SUBMIT_BUTTON.ACTIVE_RETURN_COMPLETE.value:
        return handleActiveReturnCompleted();
      case TROUBLESHOOT_SUBMIT_BUTTON.CANCEL_TICKET.value:
        return handleCloseTicket();
      default:
        return null;
    }
  };

  return {
    handleFormSubmit,
    completeLoading,
    saveLoading,
    requiredReplacementHub,
    setRequiredReplacementHub,
  };
}

export default useSubmitTroubleshootForm;
