import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "react-router-dom";
import Hub from "./Hub";
import HubList from "./HubList";

export default function AwsDemo({ programId }) {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const hubId = queryParams.get("hub");

  return !hubId ? <HubList programId={programId} /> : <Hub hubId={hubId} />;
}
AwsDemo.propTypes = {
  programId: PropTypes.string.isRequired,
};
